import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
// Components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
  FirstScreen,
  WhyUs,
  StagesOfWork,
  StagesOfWorkSlider,
  CompletedProjects,
  SolarPowerPlants,
  SolarPowerPlantsSmall,
  OurClients,
} from "../components/HomePage"
import { FormOrder } from '../components/Global'
// yaml
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLHome from "../content/ru/home.yaml"

const IndexPage = ({location}) => {
  const data = useStaticQuery(graphql`
    query {
      seo: directusHomeSeo {
        title
        description
      }
      allDirectusWhyIsList {
        edges {
          node {
            advantage_1
            advantage_2
            advantage_3
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 918) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      },
      allDirectusWhyUsIconsRu {
        edges {
          node {
            description
            icon {
              localFile {
                publicURL
              }
            }
            id
          }
        }
      },
      projects: allDirectusOurProject(filter: {show_on_main: {eq: true}}, limit: 4, sort: {fields: id}) {
        edges {
          node {
            id
            title_photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 588) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            slug
            title
          }
        }
      },
      slider: allDirectusMainSlide(sort: {fields: sort}) {
        edges {
          node {
            id
            button_link
            button_text
            subtitle
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1703) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      },
      solar: allDirectusMainPrice {
        edges {
          node {
            auto_list
            auto_list_minus
            auto_price
            auto_title
            econom_list
            econom_list_minus
            econom_price
            econom_title_ru
            reserve_list
            reserve_price
            reserve_title
            reverse_list_minus
          }
        }
      },
      clients: allDirectusHomeTestimonialsRu {
        edges {
          node {
            project
            id
            name
            slug
            link
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 628) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      },
      stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
        edges {
          node {
            id
            list
            sort
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  
  const [stages, setStages] = useState(null);
  
  const indexLocal = {
    langBase: "",
    YAMLGlobal,
    YAMLContent: YAMLHome
  }

  function toggleStages() {
    if (document.documentElement.clientWidth <= 992) {
      setStages(<StagesOfWorkSlider stages={data.stages.edges}/>)
    } else {
      setStages(<StagesOfWork stages={data.stages.edges}/>)
    }
  }
  
  useEffect(() => {
    toggleStages();

    window.addEventListener('resize', toggleStages);
    return () => window.removeEventListener('resize', toggleStages);
  }, [])
    
  function unwrapLiList(list) {
    return list.match(/<li[^>]*>(.*?)<\/li>/g);
  }
  
  function createSolarItem(id, price, name, listPlus, listMinus, link) {
    let obj = {
      id,
      currency: "$",
      price,
      name,
      list: [],
      link
    };

    listPlus.map((plus) => {
      return obj.list.push({
        title: plus.match(/<li[^>]*>(.*?)<\/li>/)[1],
        type: "+"
      });
    });

    listMinus.map((minus) => {
      return obj.list.push({
        title: minus.match(/<li[^>]*>(.*?)<\/li>/)[1],
        type: "-"
      });
    })

    return obj;
  }
  
  const solarPowerContent = [
    createSolarItem(
      0,
      data.solar.edges[0].node.econom_price,
      data.solar.edges[0].node.econom_title_ru,
      unwrapLiList(data.solar.edges[0].node.econom_list),
      unwrapLiList(data.solar.edges[0].node.econom_list_minus),
      "/services-business"
    ),
    createSolarItem(
      1,
      data.solar.edges[0].node.reserve_price,
      data.solar.edges[0].node.reserve_title,
      unwrapLiList(data.solar.edges[0].node.reserve_list),
      unwrapLiList(data.solar.edges[0].node.reverse_list_minus),
      "/services-reserve"
    ),
    createSolarItem(
      2,
      data.solar.edges[0].node.auto_price,
      data.solar.edges[0].node.auto_title,
      unwrapLiList(data.solar.edges[0].node.auto_list),
      unwrapLiList(data.solar.edges[0].node.auto_list_minus),
      "/services-auto"
    )
  ]
  
  return (
    <Layout local={indexLocal} location={location}>
      <SEO title={data.seo.title} description={data.seo.description} />
      <FirstScreen slides={data.slider.edges} />
      <WhyUs 
        list={data.allDirectusWhyIsList.edges[0].node}
        icons={data.allDirectusWhyUsIconsRu.edges}
      />
      { stages }
      <CompletedProjects projects={data.projects.edges} />
      <SolarPowerPlants content={solarPowerContent} />
      <SolarPowerPlantsSmall content={solarPowerContent} />
      <OurClients clients={data.clients.edges} />
      <FormOrder withMap={true} background="white" wave="gray"/>
    </Layout>
  )
  
}

export default IndexPage